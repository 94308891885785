.profile-header-card {
    --background: linear-gradient(90deg, rgba(244, 91, 161, 0.95) -13.88%, rgba(250, 209, 71, 0.95) 117.54%);
    border-radius: 0;
    box-shadow: none;
    margin: 0;
}

.profile-header-card > ion-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10vh;
    transition: 0.3s;
}

.profile-header-card > img {
    width: 20vh;
    margin: 0 auto;
    border-radius: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 2;
    transition: 0.3s;
}

.profile-header-card > ion-card-content {
    background: white;
    padding-top: calc(10vh + 1rem);
    margin-top: -10vh;

    transition: 0.3s;
    text-align: center;

    z-index: 1;
}

.profile-header-card > ion-card-content  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--ion-color-light-contrast)
}

.profile-header-card > ion-card-content  p {
    font-size: 1rem;
    color: var(--ion-color-light-contrast)
}


.powered-by {
    display: flex;
    font-size: 1rem;
    margin-left: auto;
    align-items: flex-end;

    color: var(--ion-color-dark, #000);
}

.powered-by ion-icon {
    padding: 0;
    margin-left: 0.5ch;
    margin-bottom: 0.1rem;
    height: 1.4rem;
    width: 2.4rem;
}



.profile-header-wrapper--briefly ion-card-header {
    padding-bottom: 0;
    transition: 0.3s;
    opacity: 0;
    height: 0;
}

.profile-header-wrapper--briefly img {
    width: 5rem;
    transition: 0.3s;
}
.profile-header-wrapper--briefly ion-card-content {
    margin-top: -2.5rem;
    transition: 0.3s;
}