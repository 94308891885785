.page--outlet-profile ion-header ion-toolbar {
    --background: var(--ion-background-color, #fff);
}

.page--outlet-profile ion-header ion-title {
    text-align: left;
    font-size: 1.5rem;
}

.page--outlet-profile ion-header ion-title ion-icon {
    margin-bottom: -0.1rem;
}

.page--outlet-profile ion-header ion-chip {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
}

.page--outlet-profile ion-header ion-chip > ion-icon {
    margin-left: 0;
    padding-left: 0;
    margin-inline: 0;
}


.page--outlet-profile__header{
    background: linear-gradient(90deg, rgba(244, 91, 161, 0.76) -13.88%, rgba(250, 209, 71, 0.76) 117.54%);
    display: grid;
    grid-template-columns: 6.25rem auto;
    grid-template-rows: auto;
}

.page--outlet-profile__header>  ion-avatar{
    width: 5.75rem;
    height: 5.75rem;
    grid-column: 1 /2;
    grid-row: 1 /3;
    justify-self: flex-start;
    align-self: center;
}
.page--outlet-profile__header > ion-title{
    grid-column: 2 /3;
    grid-row: 1 /1;
}

.page--outlet-profile__header > div{
    grid-column: 2 /3;
    grid-row: 2 /3;

    overflow-x: scroll;
    margin-top: 0.5rem;
    display: ruby;
}

.page--outlet-profile__header > div ion-chip.ion-color-success{
    background: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
}

.page--outlet-profile__header > div ion-chip.ion-color-danger{
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
}

.venue-item-picture{
    border-radius: 100%;
    margin-bottom: 1.5rem;
    overflow: hidden;
    margin-inline: auto;
    width: 13rem;
    height: 13rem;
    object-fit: cover;
}

.info-line{
    display: grid;
    grid-template-columns: 2rem 1fr;
    margin-top: 1.5rem;
}

.info-line:before{
    content: '';
    display: block;
    width: 1.5rem;
    height: 0.25rem;
    background-color: var(--ion-color-step-600, #7459D9);
    margin-top: 0.5rem;
}


.info-line :first-child > *{
    padding: 0;
    margin: 0;
    font-size: 0.7rem;
}

.info-line :last-child {
    grid-column-start: 2;
    grid-row-start: 2;
}
